@import "palettes";
@import "theme";
@import "mixins";

input {
  width: $page / 3;
  height: 40px;
  max-width: 100%;
  margin: 0;
  padding: 5px 10px;
  border: solid 1px $gray-800;
  border-radius: 5px;
  font-family: $sans;
  font-size: 1rem;
}
